// React imports

// Remix imports
import { useFetcher, useLocation } from '@remix-run/react'

// Third party imports

// Generated imports

// App imports
import InputSelectText from '~/components/inputs/InputSelectText'
import useFormsDisabled from '~/hooks/useFormsDisabled'
import useLang from '~/hooks/useLang'
import useUserData from '~/hooks/useUserData'
import urlReverse from '~/utils/urlReverse'

// ============================================================================
// HTML =======================================================================
// ============================================================================
const CurrencySwitcherForm: React.FC = () => {
  const lang = useLang()
  const location = useLocation()
  const disabled = useFormsDisabled()
  const fetcher = useFetcher()
  const nextURL = encodeURI(location.pathname + location.search)
  const userData = useUserData()

  const defaultValue = userData?.currentCurrency?.isoCode || 'USD'

  if (fetcher.state === 'idle' && fetcher.data != null) {
    window.location.reload()
  }

  return (
    <fetcher.Form
      autoComplete="off"
      method="post"
      className="h-[24px]"
      key={defaultValue}
    >
      <InputSelectText
        name="iso_code"
        options={[
          ['USD', 'USD ($)'],
          ['SGD', 'SGD (S$)'],
        ]}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={(e) => {
          fetcher.submit(
            { iso_code: e.target.value, next: nextURL },
            {
              method: 'post',
              action: urlReverse('setCurrency', { lang }),
            }
          )
        }}
      />
    </fetcher.Form>
  )
}

export default CurrencySwitcherForm
