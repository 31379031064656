/**
 * See main.css for c-dropdown, c-dropdown-content, c-collapsible-container
 * and c-collapsible content styles.
 */
// React imports
import { Link, useLocation } from '@remix-run/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ButtonCreateAccountOrProfileSelectText from '~/components/buttons/ButtonCreateAccountOrProfileSelectText';
import ButtonIconCart from '~/components/buttons/ButtonIconCart';
import ButtonIconHamburger from '~/components/buttons/ButtonIconHamburger';
import Container from '~/components/containers/Container';
import CurrencySwitcherForm from '~/components/forms/CurrencySwitcherForm';
import TheArtlingLogoUrl from '~/static/svg/TheArtlingLogo.svg';

// Remix imports

// Third party imports

// App imports
import LinkOrInternalLinkOrDiv from '~/components/links/LinkOrInternalLinkOrDiv';
import { ModalsContext } from '~/components/modals/Modals';
import DesktopSubnav from '~/components/nav/DesktopSubnav';
import MobileNav from '~/components/nav/MobileNav';
import useBlockBodyScroll from '~/hooks/useBlockBodyScroll';
import useGlobal from '~/hooks/useGlobal';
import useIsMobile from '~/hooks/useIsMobile';
import useLang from '~/hooks/useLang';
import useUserData from '~/hooks/useUserData';
import AddedToCartModal from '~/routes/$lang.modals/added-to-cart';
import eventBus from '~/utils/eventBus';
import NewGlobalSearchForm from '../forms/NewGlobalSearchForm';
import urlReverse from '~/utils/urlReverse';

// 74 + 65 + 30 (topbar + search bar +top margin)
export const MAIN_NAV_HEIGHT_MOBILE = 'mt-[170px] ';
export const MAIN_NAV_HEIGHT_CLEAN = 'mt-[140px] ';
export const SCREENHEIGHT_MOBILE = 'h-[calc(100vh-140px)] ';

const NavigationMainNew: React.FC = () => {
  const lang = useLang();
  const global = useGlobal();
  const userData = useUserData();
  const location = useLocation();
  const pathname = location.pathname;
  const [showCartPopup, setShowCartPopup] = useState(false);
  const isAuthenticated = userData?.isAuthenticated;
  const { isMobile } = useIsMobile();
  const [showMenu, setShowMenu] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState('');
  useBlockBodyScroll(showMenu || showSubmenu !== '');
  const modalsCtx = useContext(ModalsContext);
  const modalsCtxShow = modalsCtx?.show;

  const isTradeView = useMemo(() => {
    return pathname === urlReverse('profileTrade', { lang });
  }, [lang, pathname]);

  useEffect(() => {
    eventBus.on('addedToCart', (data) => {
      if (modalsCtxShow && data.isMobile) {
        modalsCtxShow('AddedToCartModal', <AddedToCartModal />);
      } else {
        setShowCartPopup(true);
      }
    });
    return function cleanup() {
      eventBus.remove('addedToCart', () => {});
    };
  }, [lang, modalsCtxShow]);

  if (!global) {
    return null;
  }

  const cart = userData?.cart;
  const trans = global.trans;
  const mainNavItems = global.mainNavItems;

  const renderLogo = () => {
    return (
      <Link to={`/${lang}/`}>
        <img
          src={TheArtlingLogoUrl}
          alt="The Artling Logo"
          className="h-[50px] w-[81px]" />

      </Link>);

  };

  const renderGlobalSearchForm = () => {
    return (
      <NewGlobalSearchForm
        lang={lang}
        placeholderText={trans.WhatAreYouLookingFor}
        placeholderTextShort={trans.Search} />);


  };

  const renderCart = (width: '22px' | '35px') => {
    return (
      <ButtonIconCart
        width={width}
        isAuthenticated={isAuthenticated}
        lang={lang}
        trans={trans}
        cart={cart}
        showCartPopup={showCartPopup}
        setShowCartPopup={setShowCartPopup}
        isMobile={isMobile} />);


  };

  const renderTopBar = () => {
    return (
      <div className="flex h-[74px] flex-row items-center border-0 border-b border-brand-bg-border md:h-[60px]">
        <Container>
          <div className="flex w-full flex-grow flex-row">
            <div className="flex flex-col justify-center md:hidden">
              <ButtonIconHamburger
                setShowMenu={setShowMenu}
                isAuthenticated={isAuthenticated}
                trans={trans}
                mainNavItems={mainNavItems} />

            </div>
            <div className="mx-[20px] md:hidden">{renderLogo()}</div>
            <div className="flex-grow">&nbsp;</div>
            <div className="flex flex-row items-center gap-6">
              <div className="hidden md:block">
                <CurrencySwitcherForm />
              </div>
              <div className="hidden md:block">
                <div className="flex gap-4">{renderCart('22px')}</div>
              </div>
              <ButtonCreateAccountOrProfileSelectText
                isAuthenticated={isAuthenticated}
                lang={lang}
                trans={trans}
                userData={userData} />

            </div>
          </div>
        </Container>
      </div>);

  };

  const renderMainNavItems = () => {
    return (
      <div className="flex flex-row items-center justify-between gap-6 md:gap-5 lg:gap-14">
        {mainNavItems.map((item, index) => {
          return (
            <LinkOrInternalLinkOrDiv
            // TODO links: replace when all items have been migrated
            key={item.name}
            to={item.path}
            prefetch="intent"
            className={`h-full hover:text-brand-gold`}
            useInternalLink={item.useInternalLink}
            onClick={(e) => {
              if (item.subItems.length > 0) {
                e.preventDefault();
                e.stopPropagation();
                setShowSubmenu(item.name);
              }
            }}>

              <p className="font-sansSerif text-p2 antialiased">{item.name}</p>
            </LinkOrInternalLinkOrDiv>);

        })}
      </div>);

  };

  const renderMainNav = () => {
    return (
      <div className="border-0 border-b border-brand-bg-border md:border-b-0">
        <Container>
          <div className="flex h-[65px] flex-row items-center justify-between md:h-[95px]">
            <div className="hidden md:block">{renderLogo()}</div>
            <div className="hidden md:block">{renderMainNavItems()}</div>
            <div className="2xl:w-[270px] relative w-full md:w-[200px] lg:w-[250px]">
              {!isTradeView && renderGlobalSearchForm()}
            </div>
            <div className="ml-[21px] block md:hidden">
              <div className="flex gap-4">{renderCart('35px')}</div>
            </div>
          </div>
        </Container>
      </div>);

  };

  return (
    <React.Fragment>
      <div className="fixed top-0 z-40 w-full bg-brand-bg md:relative">
        {renderTopBar()}
        {renderMainNav()}
      </div>
      {showMenu &&
      <MobileNav
        lang={lang}
        trans={trans}
        setShowMenu={setShowMenu}
        mainNavItems={mainNavItems}
        isAuthenticated={isAuthenticated} />}


      {showSubmenu &&
      <DesktopSubnav
        mainNavItems={mainNavItems}
        setShowSubmenu={setShowSubmenu}
        showSubmenu={showSubmenu}
        trans={trans} />}


    </React.Fragment>);

};

export default NavigationMainNew;