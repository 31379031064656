// React imports

// Remix imports

// Third party imports

// Generated imports

// App imports
import BadgeNumberDynamic from '~/components/badges/BadgeNumberDynamic'
import type { CartFastFragment } from '~/generated/graphql'
import useGlobal from '~/hooks/useGlobal'
import IconCartOutlineSvgUrl from '~/static/svg/IconCartOutline.svg'

interface Props {
  cart?: CartFastFragment
  width: '22px' | '35px'
}

const CartIconWithBadge: React.FC<Props> = ({ cart, width }) => {
  const global = useGlobal()
  const trans = global.trans
  const cartItems = cart?.items || []

  return (
    <div className="relative">
      <img
        src={IconCartOutlineSvgUrl}
        alt={trans.Cart}
        style={{ width: width }}
      />
      {cartItems?.length > 0 && (
        <BadgeNumberDynamic
          className="absolute top-[-4px] right-[-6px] border-0 border-white"
          width={14}
          value={cartItems?.length}
        />
      )}
    </div>
  )
}

export default CartIconWithBadge
