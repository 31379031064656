// React imports
import { useEffect, useRef } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useClickOutside = (clickHandler: (isHit: boolean) => void) => {
  const ref = useRef<any | null>(null)

  useEffect(() => {
    const handleMouseClick = (e: any) => {
      if (ref.current) {
        const box = ref.current.getBoundingClientRect()
        const clickX = e.clientX
        const clickY = e.clientY
        let clickIsInsideBox = false
        if (
          clickX >= box.left &&
          clickX <= box.right &&
          clickY >= box.top &&
          clickY <= box.bottom
        ) {
          clickIsInsideBox = true
        }
        clickHandler(clickIsInsideBox)
      }
    }

    document.addEventListener('click', handleMouseClick)
    return function cleanup() {
      document.removeEventListener('click', handleMouseClick, false)
    }
  }, [clickHandler])
  return { ref }
}

export default useClickOutside
