import { Link } from '@remix-run/react'
import React from 'react'
import CurrencySwitcherForm from '~/components/forms/CurrencySwitcherForm'
import H1 from '~/components/typography/H1'
import type { Language } from '~/i18n.universal'
import IconCloseXSvgUrl from '~/static/svg/IconCloseX.svg'
import type { GlobalTrans } from '~/utils/getGlobalTrans'
import type { MainNavItem } from '~/utils/getMainNavItems'

interface Props {
  trans: GlobalTrans
  setShowMenu: (showMenu: boolean) => void
  mainNavItems: MainNavItem[]
  isAuthenticated: boolean
  lang: Language
}

const MobileNav: React.FC<Props> = ({
  isAuthenticated,
  trans,
  setShowMenu,
  mainNavItems,
  lang,
}) => {
  const PADDINGX = 'px-[30px]'
  const PADDINGY = 'py-[30px]'
  const MARGIN_B = 'mb-[20px]'

  const renderDivider = () => {
    return (
      <div
        className={`${MARGIN_B} h-0 border-0 border-t border-brand-bg-border`}
      >
        &nbsp;
      </div>
    )
  }

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 overflow-y-scroll bg-white md:hidden">
      <div className={`${PADDINGX} ${PADDINGY} pb-0`}>
        <div className="mb-[48px] w-full text-right">
          <img
            src={IconCloseXSvgUrl}
            alt={trans.Close}
            className="inline-block cursor-pointer"
            onClick={() => {
              setShowMenu(false)
            }}
          />
        </div>
        {mainNavItems.map((item, index) => {
          return (
            <div
              key={index}
              className={`${MARGIN_B}`}
            >
              <Link
                key={index}
                to={item.path}
                onClick={() => {
                  setShowMenu(false)
                }}
              >
                <H1 className="font-normal">{item.name}</H1>
              </Link>
            </div>
          )
        })}
      </div>

      {renderDivider()}

      <div className={`${PADDINGX} ${MARGIN_B}`}>
        <CurrencySwitcherForm />
      </div>

      {renderDivider()}
    </div>
  )
}

export default MobileNav
