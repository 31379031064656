// React imports
import React from 'react'

import { useFetcher } from '@remix-run/react'

// Third party imports

// Generated imports

// App imports
import Container from '~/components/containers/Container'
import NewsletterSignupForm from '~/components/forms/NewsletterSignupForm'
import LinkOrInternalLinkOrDiv from '~/components/links/LinkOrInternalLinkOrDiv'
import P2 from '~/components/typography/P2'
import useGlobal from '~/hooks/useGlobal'
import IconFacebookUrl from '~/static/svg/IconFacebook.svg'
import IconInstagramUrl from '~/static/svg/IconInstagram.svg'

const Footer: React.FC = () => {
  const global = useGlobal()
  const fetcher = useFetcher()

  if (!global) {
    return null
  }

  const trans = global.trans
  const footerItems = global.footerItems

  return (
    <div className="mt-3 bg-section-bg py-[60px]">
      <Container>
        <div className="mx-auto mb-next-section md:w-[290px]">
          <NewsletterSignupForm
            FormElement={fetcher.Form}
            actionData={fetcher.data}
          />
          <div className="mt-3 flex justify-center gap-5">
            <a
              href="https://www.facebook.com/theartling"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="w-[20px]"
                src={IconFacebookUrl}
                alt={trans.TheArtlingOnFacebook}
              />
            </a>

            <a
              href="https://www.instagram.com/theartling/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="w-[20px]"
                src={IconInstagramUrl}
                alt={trans.TheArtlingOnInstagram}
              />
            </a>
          </div>
        </div>
        <div className="block md:flex md:gap-8">
          <div className="grid flex-grow grid-cols-2 md:mt-0 md:grid-cols-6">
            {footerItems.map((footerItem, index) => (
              <div
                key={index}
                className="mt-8 w-[50%] md:mt-0 md:w-auto"
              >
                {!footerItem.path && (
                  <P2>
                    <b>{footerItem.name}</b>
                  </P2>
                )}
                {!!footerItem.path && (
                  <a
                    href={footerItem.path}
                    rel="noreferrer"
                  >
                    <P2>
                      <b>{footerItem.name}</b>
                    </P2>
                  </a>
                )}

                <ul className="">
                  {footerItem.subItems.map((subItem, index) => (
                    <li
                      key={index}
                      className="mt-3"
                    >
                      {!subItem.useExternalLink && (
                        <LinkOrInternalLinkOrDiv
                          to={subItem.path}
                          useInternalLink={subItem.useInternalLink}
                          prefetch="intent"
                        >
                          <P2>{subItem.name}</P2>
                        </LinkOrInternalLinkOrDiv>
                      )}
                      {!!subItem.useExternalLink && (
                        <a
                          href={subItem.path}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <P2>{subItem.name}</P2>
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="text-section-bg">
          <a
            className="cursor-default select-none"
            href={`${global.ENV.DJANGO_URL}/products/`}
          >
            Products
          </a>
        </div>
      </Container>
    </div>
  )
}

export default Footer
