// React imports
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// Remix imports
import { Link, useFetcher, useLocation, useNavigate } from '@remix-run/react'

// Third party imports
import reduce from 'lodash/reduce'
import SparkMD5 from 'spark-md5'

// Generated imports

// App imports
import Box from '~/components/containers/Box'
import InputTextSearch from '~/components/inputs/InputTextSearch'
import useGlobal from '~/hooks/useGlobal'
import useIsMd from '~/hooks/useIsMd'
import useIsMobile from '~/hooks/useIsMobile'
import type { Language } from '~/i18n.universal'
// import IconCameraSvgUrl from '~/static/svg/IconCamera.svg'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import HRBox from '~/components/typography/HRBox'
import IconUpload from '~/static/svg/IconUpload'
import InputImageUpload from '../inputs/InputImageUpload'
import P2 from '../typography/P2'
import P3 from '../typography/P3'
import P4 from '../typography/P4'
import useUserData from '~/hooks/useUserData'

interface Props {
  lang: Language
  placeholderText: string
  placeholderTextShort: string

  /** For storyboard */
  fixtures?: string[]
  hasFocusFixtures?: boolean
}

const NewGlobalSearchForm: React.FC<Props> = ({
  lang,
  placeholderText,
  placeholderTextShort,
  fixtures,
  hasFocusFixtures,
}) => {
  const global = useGlobal()
  const location = useLocation()
  const [showPopup, setShowPopup] = useState<string>('')
  const [currentValue, setCurrentValue] = useState('')
  const [containerHeight, setContainerHeight] = useState(0)
  const [imageLoading, setImageLoading] = useState(false)
  const { isStaff } = useUserData()

  const refContainer = useRef(null) as any
  const formRef = useRef<HTMLFormElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const { isMobile } = useIsMobile()
  const trans = global.trans
  const fetcher = useFetcher()
  const baseFilter = isStaff ? 'other=below_minimum&other=inactive' : ''
  const navigate = useNavigate()

  useEffect(() => {
    setShowPopup('')
  }, [location])

  const changeHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const value = get(e, 'target.value')
      fetcher.load(
        `/${lang}/remix/search/?search=${value}&withJS=true&${baseFilter}`
      )
    },
    [baseFilter, fetcher, lang]
  )

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    [changeHandler]
  )

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  const { isMd } = useIsMd()

  const data = fixtures || get(fetcher, 'data.data', [])

  const textContainerHeight = useMemo(() => {
    if (!currentValue) {
      return 0
    }
    let height = (textRef.current?.offsetHeight || 0) + 4
    if (data.length > 0) {
      height += 4 + 1
    }
    const lineHeight = isMobile ? 55 : 52
    height += lineHeight * data.length
    return height
  }, [data, currentValue, isMobile])

  const imgHash = (img: any, onDone: (img: any) => void): void => {
    setImageLoading(true)
    if (img.file) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        if (e.target) {
          const result = e.target.result
          const ext = img.name.split('.').pop()
          const hash = SparkMD5.ArrayBuffer.hash(result, true)
          const hexStr = reduce(
            hash,
            (acc, curr) => {
              let charHex = curr.charCodeAt(0).toString(16)
              if (charHex.length < 2) {
                charHex = '0' + charHex
              }
              return acc + charHex
            },
            ''
          )
          img.name = `${hexStr}.${ext}`
          onDone(img)
        }
      }
      reader.readAsArrayBuffer(img.file)
    }
  }

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (
        !document.getElementById('GlobalSearchForm')?.contains(e.target as Node)
      ) {
        setShowPopup('')
      }
    })
    return () => {
      window.removeEventListener('click', () => {})
    }
  }, [])

  useEffect(() => {
    if (refContainer.current) {
      setContainerHeight(refContainer.current.clientHeight + 25)
    }
  }, [imageLoading])

  let placeholderTextStr = placeholderText
  if (isMd) {
    placeholderTextStr = placeholderTextShort
  }

  return (
    <fetcher.Form
      className="relative w-full"
      method="get"
      action={`/${lang}/remix/search/`}
      onChange={debouncedChangeHandler}
      onSubmit={(e) => {
        // TODO: figure out what to do for different searches
        // trackSearchGA4(currentValue)
        e.preventDefault()
        e.stopPropagation()
        const formData = new FormData(formRef.current!)
        const search = formData.get('search')

        let finalSearch = ''
        if (search) {
          finalSearch = `search:"${search}"`
        }

        const url = `/${lang}/search/?search=${finalSearch}&${baseFilter}`
        navigate(url)
      }}
      id="GlobalSearchForm"
      ref={formRef}
    >
      <InputTextSearch
        showCamera={true}
        className="mb-0 bg-white"
        inputType="normal"
        trans={trans}
        disabled={false}
        name="search"
        placeholder={placeholderTextStr}
        onFocus={() => {
          setShowPopup('text')
        }}
        handleCamera={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShowPopup('image')
        }}
        onChange={(e) => {
          setCurrentValue(e.target.value)
        }}
        aria-autocomplete="none"
        autoComplete="off"
      />

      <Box
        paddingClassName="p-0"
        className={
          'absolute top-[35px] right-0 z-10 w-full overflow-y-hidden bg-white transition-all duration-300 ease-in-out'
        }
        style={{
          height: showPopup === 'image' ? `${containerHeight}px` : '0px',
          border: showPopup === 'image' ? '1px solid #e5e5e5' : 'none',
          paddingBottom: showPopup === 'image' ? '0.5rem' : 0,
        }}
      >
        <div ref={refContainer}>
          <P2 className="m-3 !font-sansSerif">{trans.VisualSearch}</P2>
          <div className="mb-3 w-full px-3">
            <InputImageUpload
              className="!h-[160px] "
              isLoading={imageLoading}
              dest="image_cache"
              name="hash_img"
              onChangeAsync={imgHash}
              onChange={(val) => {
                const url = `/${lang}/search/?hash_img=${val}&${baseFilter}`
                navigate(url)
              }}
              notSquare={true}
              UploadLogo={IconUpload}
              infoText={
                trans.LookingForSimilarWorksDragAndDropOrClickToUploadAnImageHere
              }
              bigText={true}
              setImageLoading={setImageLoading}
            />
          </div>
        </div>
      </Box>
      <Box
        paddingClassName="p-0"
        className={
          'absolute top-[35px] right-0 z-10 w-full overflow-y-hidden bg-white transition-all duration-300 ease-in-out'
        }
        style={{
          height:
            showPopup === 'text' && currentValue
              ? `${textContainerHeight}px`
              : '0px',
          border:
            showPopup === 'text' && currentValue ? '1px solid #e5e5e5' : 'none',
        }}
      >
        <div
          className="px-3 py-2 hover:bg-gray-bg-2"
          ref={textRef}
        >
          <Link to={`/${lang}/search/?search=${currentValue}&${baseFilter}`}>
            <P3
              dangerouslySetInnerHTML={{
                __html: trans.ClickHereToSearchForArtistsArtworkTitlesAndMore,
              }}
              className="cursor-pointer hover:bg-gray-bg-2"
            />
          </Link>
        </div>
        {data.length > 0 && <HRBox className="!my-1" />}
        {data.map((item: string, index: number) => {
          const itemData = JSON.parse(item)
          return (
            <Link
              className="z-20 hover:no-underline"
              key={index}
              to={itemData.url}
              prefetch="intent"
            >
              <div className={'px-3 py-2 hover:bg-gray-bg-2'}>
                <P2 className="truncate">{itemData.name}</P2>
                <P4 className="truncate text-text-muted">
                  {trans.CategoryNameByArtistName.replace(
                    '%(categoryName)s',
                    itemData.category
                  ).replace('%(artistName)s', itemData.artist)}
                </P4>
              </div>
            </Link>
          )
        })}
      </Box>
    </fetcher.Form>
  )
}

export default NewGlobalSearchForm
