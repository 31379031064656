// React imports
import React from 'react'

// Remix imports
import { Link } from '@remix-run/react'

// Third party imports

// Generated imports

// App imports
import Button from '~/components/buttons/Button'
import CartIconWithBadge from '~/components/cart/CartIconWithBadge'
import Icon from '~/components/icons/Icon'
import HR from '~/components/typography/HR'
import P3 from '~/components/typography/P3'
import type { CartFastFragment } from '~/generated/graphql'
import useClickOutside from '~/hooks/useClickOutside'
import useEscKey from '~/hooks/useEscKey'
import useFade from '~/hooks/useFade'
import useGetAmountInCurrentCcy from '~/hooks/useGetAmountInCurrentCcy'
import useGlobal from '~/hooks/useGlobal'
import useLang from '~/hooks/useLang'
import useLocationChange from '~/hooks/useLocationChange'
import IconCloseSvgURL from '~/static/svg/IconClose.svg'
import urlReverse from '~/utils/urlReverse'

interface Props {
  onClose: () => void
  show?: boolean
  cart?: CartFastFragment
}

const CartPopup: React.FC<Props> = ({ cart, show, onClose }) => {
  const lang = useLang()
  const global = useGlobal()
  const trans = global.trans

  const { opacity, display } = useFade(!!show, 500)
  const handleMouseClick = (isHit: boolean) => {
    if (show && display)
      if (!isHit) {
        onClose()
      }
  }

  const { ref } = useClickOutside(handleMouseClick)
  const { getAmountInCurrentCcy } = useGetAmountInCurrentCcy(0)
  useEscKey(() => onClose())
  useLocationChange(onClose)

  const cartItems = cart?.items || []
  const cartSubtotal = cart?.cartSubtotal

  return (
    <div
      ref={ref}
      className={
        'absolute top-[100%] right-0 z-40 mt-1 w-[230px] rounded-md border border-black bg-white p-2 transition-all duration-500 ease-in-out' +
        (display ? ' block' : ' hidden') +
        (opacity ? ' opacity-100' : ' opacity-0')
      }
    >
      <div className="mt-1 flex w-full justify-between">
        <CartIconWithBadge
          cart={cart}
          width="22px"
        />
        <Icon
          alt={trans.CloseThisModal}
          width="13px"
          onClick={() => {
            onClose()
          }}
          src={IconCloseSvgURL}
          hover={true}
        />
      </div>
      <HR className="mt-3 mb-1" />
      {cartItems.length === 0 && (
        <P3 className="my-3 text-center">{trans.YourCartIsEmpty}</P3>
      )}

      {cartItems?.length > 0 && (
        <React.Fragment>
          {cartItems.map((item) => (
            <Link
              to={item.itemAbsoluteUrl || ''}
              key={item.id}
              prefetch="intent"
            >
              <div className="mt-3 flex">
                <div className="w-[60px] flex-none">
                  <img
                    src={item.itemImage || ''}
                    alt={item.itemProductName}
                    className="h-[60px] w-[60px]"
                  />
                </div>
                <div className="ml-2 w-[100%] overflow-x-hidden">
                  <P3 className="overflow-x-hidden text-ellipsis whitespace-nowrap font-bold">
                    {item.itemProductName}
                  </P3>
                  {item.itemArtistName && (
                    <P3 className="overflow-x-hidden text-ellipsis whitespace-nowrap">
                      {trans.By.toLowerCase()} {item.itemArtistName}
                    </P3>
                  )}
                  <P3 className="text-right">
                    {getAmountInCurrentCcy(item.totalPrice)}
                  </P3>
                </div>
              </div>
            </Link>
          ))}
        </React.Fragment>
      )}
      {cartItems.length > 0 && cartSubtotal && (
        <React.Fragment>
          <HR className="my-2" />
          <div className="flex justify-between">
            <P3>{trans.Subtotal}</P3>
            <P3>{getAmountInCurrentCcy(cartSubtotal)}</P3>
          </div>
        </React.Fragment>
      )}

      {cartItems.length > 0 && (
        <div className="my-6 flex justify-center">
          <Link
            to={urlReverse('cart', { lang })}
            prefetch="intent"
          >
            <Button dataCy="go-to-cart">{trans.GoToCart}</Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default CartPopup
