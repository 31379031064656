import { useNavigate } from '@remix-run/react'
import CartIconWithBadge from '~/components/cart/CartIconWithBadge'
import CartPopup from '~/components/cart/CartPopup'
import InternalLink from '~/components/links/InternalLink'
import type { CartFastFragment } from '~/generated/graphql'
import type { Language } from '~/i18n.universal'
import type { GlobalTrans } from '~/utils/getGlobalTrans'
import urlReverse from '~/utils/urlReverse'

interface Props {
  className?: string
  isAuthenticated: boolean
  lang: Language
  trans: GlobalTrans
  isMobile?: boolean
  setShowCartPopup: (show: boolean) => void
  showCartPopup: boolean
  cart?: CartFastFragment
  width: '22px' | '35px'
}

const ButtonIconCart: React.FC<Props> = ({
  className,
  isAuthenticated,
  lang,
  trans,
  isMobile,
  setShowCartPopup,
  showCartPopup,
  cart,
  width,
}) => {
  const navigate = useNavigate()
  const closeCartPopup = () => {
    setShowCartPopup(false)
  }

  return (
    <div className={'relative' + (className ? ` ${className}` : '')}>
      <InternalLink
        // TODO links: replace when favs view is migrated
        to={`${urlReverse('cart', { lang })}`}
        prefetch="intent"
        className="contents"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (isMobile) {
            navigate(urlReverse('cart', { lang }))
          } else {
            setShowCartPopup(!showCartPopup)
          }
          return false
        }}
      >
        <CartIconWithBadge
          cart={cart}
          width={width}
        />
      </InternalLink>
      {!isMobile && (
        <CartPopup
          onClose={closeCartPopup}
          show={showCartPopup}
          cart={cart}
        />
      )}
    </div>
  )
}

export default ButtonIconCart
