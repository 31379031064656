import { Link } from '@remix-run/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import Container from '~/components/containers/Container'
import LinkOrInternalLinkOrDiv from '~/components/links/LinkOrInternalLinkOrDiv'
import H1 from '~/components/typography/H1'
import P1 from '~/components/typography/P1'
import useEscKey from '~/hooks/useEscKey'
import IconCloseXSvgUrl from '~/static/svg/IconCloseX.svg'
import createMarkup from '~/utils/createMarkup'
import type { GlobalTrans } from '~/utils/getGlobalTrans'
import type { MainNavItem } from '~/utils/getMainNavItems'
import Button from '../buttons/Button'

interface Props {
  mainNavItems: MainNavItem[]
  setShowSubmenu: (mainItemName: string) => void
  trans: GlobalTrans
  showSubmenu: string
}

const DesktopSubnav: React.FC<Props> = ({
  trans,
  mainNavItems,
  setShowSubmenu,
  showSubmenu,
}) => {
  const [isMounted, setIsMounted] = useState(false)
  const [isUnmounting, setIsUnmounting] = useState(false)
  const [containerHeight, setContainerHeight] = useState(0)
  const refContainer = useRef(null) as any

  const close = useCallback(() => {
    setIsUnmounting(true)
    setTimeout(() => {
      setShowSubmenu('')
    }, 300)
  }, [setShowSubmenu])

  useEscKey(() => close())

  useEffect(() => {
    if (!isMounted && refContainer.current) {
      setIsMounted(true)
      // unclear why I need to add 10px here but without it the drawer
      // doesn't slide in far down enough
      setContainerHeight(refContainer.current.clientHeight + 10)
    }
  }, [isMounted])

  const PADDINGX = 'px-[30px]'
  const PADDINGY = 'py-[30px]'

  const activeMainNavItem = mainNavItems.find(
    (item) => item.name === showSubmenu
  )

  return (
    <div
      className="absolute top-0 bottom-0 left-0 right-0 z-40 bg-black-overlay transition-all duration-300 ease-in-out"
      style={{
        opacity: isMounted && !isUnmounting ? 1 : 0,
      }}
      onClick={(e) => {
        close()
      }}
    >
      <div
        className="top-0 left-0 right-0 z-50 hidden overflow-y-hidden bg-white transition-all duration-300 ease-in-out md:absolute md:block"
        style={{
          height: isMounted && !isUnmounting ? `${containerHeight}px` : '0',
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          return false
        }}
      >
        <div
          ref={refContainer}
          className="z-50 bg-white"
        >
          <div className={`${PADDINGX} ${PADDINGY}`}>
            <Container>
              <div className="mb-[48px] w-full text-right">
                <img
                  src={IconCloseXSvgUrl}
                  alt={trans.Close}
                  className="inline-block cursor-pointer"
                  onClick={() => {
                    close()
                  }}
                />
              </div>
            </Container>
            <Container className="!w-fit">
              <div className="flex flex-row justify-center gap-4">
                {activeMainNavItem?.subItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="border-r border-brand-bg-border px-[45px]"
                    >
                      <LinkOrInternalLinkOrDiv
                        // TODO links: replace when all items have been migrated
                        className="hover:no-underline"
                        to={item.path}
                        useInternalLink={item.useInternalLink}
                        onClick={() => {
                          close()
                        }}
                      >
                        <H1
                          className={
                            `mb-[34px] font-normal no-underline` +
                            (item.path
                              ? ' cursor-pointer hover:text-brand-gold-hover'
                              : '')
                          }
                          dangerouslySetInnerHTML={createMarkup(item.name)}
                        />
                      </LinkOrInternalLinkOrDiv>
                      {item.subSubItems?.map((subSubItem, index2) => {
                        return (
                          <LinkOrInternalLinkOrDiv
                            // TODO links: replace when all items have been migrated
                            key={index2}
                            to={subSubItem.path}
                            useInternalLink={subSubItem.useInternalLink}
                            onClick={(e) => {
                              e.stopPropagation()
                              close()
                            }}
                          >
                            <P1 className="leading-9">{subSubItem.name}</P1>
                          </LinkOrInternalLinkOrDiv>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
              {activeMainNavItem?.button && (
                <div className="mt-next-element px-[45px]">
                  <Link
                    to={activeMainNavItem.button.path}
                    prefetch="intent"
                    onClick={(e) => {
                      e.stopPropagation()
                      close()
                    }}
                  >
                    <Button
                      className="w-[240px]"
                      size="inputNormal"
                    >
                      {activeMainNavItem.button.name}
                    </Button>
                  </Link>
                </div>
              )}
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopSubnav
