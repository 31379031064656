import { Link } from '@remix-run/react'
import startCase from 'lodash/startCase'
import { useContext } from 'react'
import Button from '~/components/buttons/Button'
import InternalLink from '~/components/links/InternalLink'
import { ModalsContext } from '~/components/modals/Modals'
import P2 from '~/components/typography/P2'
import type { Language } from '~/i18n.universal'
import type { UserDataLoaderData } from '~/routes/$lang.components/user-data'
import LoginSignupModal from '~/routes/$lang.modals/login-signup'
import type { GlobalTrans } from '~/utils/getGlobalTrans'
import urlReverse from '~/utils/urlReverse'

interface Props {
  isAuthenticated: boolean
  lang: Language
  trans: GlobalTrans
  userData?: UserDataLoaderData
}

const ButtonCreateAccountOrProfileSelectText: React.FC<Props> = ({
  isAuthenticated,
  lang,
  trans,
  userData,
}) => {
  const modalsCtx = useContext(ModalsContext)
  const { isTradeUser } = userData || {}

  if (!isAuthenticated) {
    return (
      <Button
        href={`${urlReverse('login', { lang })}`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          modalsCtx?.show(
            'LoginSignupModal',
            <LoginSignupModal initialShowForm="login" />
          )
          return false
        }}
        as="a"
        size="small"
      >
        {trans.SignupLogin}
      </Button>
    )
  }

  let LinkComp = InternalLink as any
  let linkDetails = {
    to: urlReverse('profileAccountInfo', { lang }),
    text: trans.MyProfile,
  }
  if (isTradeUser) {
    LinkComp = Link
    linkDetails = {
      to: urlReverse('profileTrade', { lang }),
      text: trans.Trade,
    }
  }

  return (
    <LinkComp
      // TODO links: replace this when /en/user/account/ has been migrated
      to={linkDetails.to}
    >
      <P2>{startCase(linkDetails.text)}</P2>
    </LinkComp>
  )
}

export default ButtonCreateAccountOrProfileSelectText
