import React from 'react'
import IconHamburgerMenuBlackSvgUrl from '~/static/svg/IconHamburgerMenuBlack.svg'
import type { GlobalTrans } from '~/utils/getGlobalTrans'
import type { MainNavItem } from '~/utils/getMainNavItems'

interface Props {
  className?: string
  trans: GlobalTrans
  mainNavItems: MainNavItem[]
  isAuthenticated: boolean
  setShowMenu: (showMenu: boolean) => void
}

const ButtonIconHamburger: React.FC<Props> = ({
  className,
  trans,
  mainNavItems,
  isAuthenticated,
  setShowMenu,
}) => {
  return (
    <React.Fragment>
      <img
        src={IconHamburgerMenuBlackSvgUrl}
        alt={trans.MainMenu}
        className={
          'h-[20px] min-w-[26px] cursor-pointer' +
          (className ? ` ${className}` : '')
        }
        onClick={() => {
          setShowMenu(true)
        }}
      />
    </React.Fragment>
  )
}

export default ButtonIconHamburger
