import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  value: number | string
  bg?: string
  width: number
}

const BadgeNumberDynamic: React.FC<Props> = ({
  className,
  value,
  bg,
  width,
}) => {
  const bgColor = bg ? bg : 'bg-brand-gold'

  return (
    <div
      className={
        `flex items-center justify-center rounded-full ${bgColor} font-sansSerif text-[50%] leading-none text-white antialiased` +
        (className ? ` ${className}` : '')
      }
      style={{ width: `${width}px`, height: `${width}px` }}
    >
      {value}
    </div>
  )
}

export default BadgeNumberDynamic
